import styled from '@emotion/styled';

import variables from 'theme/styles/vars';

export default styled.div`
    background-color: #FFFFFF;
    border-radius: .3em;
    padding: 1em;
    box-shadow: 0 0 1em #0000001f;
    width: max-content;
    width: -moz-max-content;
    right: 0;
    position: relative;
    min-width: 20em;

    &:before {
        position: absolute;
        content: '';
        background-color: transparent;
        inset: -16px;
        z-index: -1;
    }

    .drop-down-list {
        border-bottom: .1em solid #F1F1F1;

        .list-title {
            font-size: 12px;
            padding-bottom: 4px;
            border-bottom: .1em solid #F1F1F1;
        }

        .list-elems {
            max-height: 500px;
            overflow-y: auto;
            padding: 1.5em 0;

            > * {
                padding-bottom: 1em;

                &:last-of-type {
                    padding-bottom: 0;
                }
            }

            .list-elem {
                display: flex;
                justify-content: space-between;
                align-items: stretch;

                .eleme-col {
                    &.col-left {
                        width: 60%;
                        display: flex;
                        align-items: stretch;
                        gap: 1em;

                        .col-image-container {
                            position: relative;
                            overflow: hidden;
                            width: 40px;
                            min-width: 40px;
                            height: 40px;
                            border-radius: .5em;

                            .image {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }

                        .col-description {
                            display: flex;
                            justify-content: center;
                            align-items: flex-start;
                            flex-direction: column;

                            .title {
                                font-size: 11px;
                                color: #262626;

                            }

                            .subtitle {
                                font-size: 10px;
                                color: #6b6b6b;
                            }
                        }
                    }

                    &.col-right {
                        display: flex;
                        justify-content: space-between;
                        align-items: stretch;

                        .col-description {
                            display: flex;
                            justify-content: center;
                            align-items: flex-start;
                            flex-direction: column;

                            .title {
                                font-size: 14px;
                                color: #262626;
                                font-weight: bold;
                            }

                            .subtitle {
                                font-size: .8em;
                                color: #6b6b6b;
                                text-decoration: line-through;
                            }
                        }
                    }

                    &.col-control {
                        align-self: center;
                        opacity: 0.5;
                        width: 1.3em;

                        @media (pointer: fine) {
                            &:hover {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    .summary {
        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1em 0;
            font-size: 14px;

            .title {
                color: #262626;
                font-weight: bold;
            }

            .price-compare {
                display: flex;
                font-weight: bold;
                gap: 0.2em;

                .marketing-price {
                    text-decoration: line-through;
                    font-weight: 300;
                }
            }
        }
    }

    @media all and (max-width: ${variables.tabletL}) {
    }
`;
