import { FunctionComponent, useContext } from 'react';
import { useRouter } from 'next/router';

import { Routes } from 'types/routes';

import { Types } from 'models/CartItem';
import Product from 'models/Product';

import { imageResizer } from 'utils/image';

import LocaleContext from 'components/context/Locale';
import Button, {
    ButtonElements,
    ButtonIconPositions,
    ButtonIconTypes,
    ButtonLayouts,
    ButtonStyles,
} from 'components/layout/Button';
import NewImage from 'components/layout/NewImage';

import { Props } from './index';
import StyledComponent from './styles';

const PublicLayoutCartDropDownMenu: FunctionComponent<Props> = ({
    translations,
    actions,
    state,
}) => {
    const localeContext = useContext(LocaleContext);
    const router = useRouter();

    translations = {
        ...localeContext?.translations?.componentCartDropDownMenu,
        ...(translations || {}),
    };

    if (state.cart?.items?.length === 0 || !state.cart?.items?.length) {
        return (
            <StyledComponent className="public-layout-cart-drop-down-menu">
                <div className="title-no-items">{translations?.title?.noItems}</div>
            </StyledComponent>
        );
    }

    return (
        <StyledComponent className="public-layout-cart-drop-down-menu">
            <div className="drop-down-list">
                <div
                    className="list-title"
                    dangerouslySetInnerHTML={{ __html: translations?.title?.cart }}
                />
                <div className="list-elems">
                    {state.cart?.items
                        .filter((cartItem) => cartItem?.isAccepted)
                        .map(cartItem => {
                            const product: Product = cartItem?.itemType === Types.Product
                                ? cartItem?.item
                                : null;

                            return (
                                <div
                                    key={cartItem.id}
                                    className="list-elem"
                                >
                                    <div className="eleme-col col-left">
                                        <div className="col-image-container">
                                            {cartItem.item?.locale?.image?.imageUrl && (
                                                <NewImage
                                                    className="image"
                                                    fileUpload={cartItem.item?.locale?.image}
                                                    resizerConfig={{ width: 100 }}
                                                />
                                            )}
                                            {(cartItem?.itemType === Types.Voucher && !cartItem.item?.locale?.image?.imageUrl) && (
                                                <NewImage
                                                    className="image"
                                                    src={imageResizer(translations?.voucherPlaceholder, { width: 100 })}
                                                    srcSet={imageResizer(translations?.voucherPlaceholder, { width: 100 }, true)}
                                                    alt="Voucher placeholder image"
                                                />
                                            )}
                                        </div>
                                        <div className="col-description">
                                            {cartItem?.itemType === Types.Product && (
                                                <>
                                                    <div
                                                        className="title"
                                                        dangerouslySetInnerHTML={{ __html: product.locale?.name }}
                                                    />
                                                    <div
                                                        className="subtitle"
                                                        dangerouslySetInnerHTML={{ __html: `${translations?.title?.quantity}: ${cartItem.quantity}` }}
                                                    />
                                                </>
                                            )}
                                            {(cartItem?.itemType === Types.Voucher || cartItem?.itemType === Types.Bundle) && (
                                                <>
                                                    <div
                                                        className="title"
                                                        dangerouslySetInnerHTML={{ __html: cartItem?.item?.locale?.name || cartItem?.data?.voucherName }}
                                                    />
                                                    {cartItem.quantity > 1 && (
                                                        <div
                                                            className="subtitle"
                                                            dangerouslySetInnerHTML={{ __html: `${translations?.title?.quantity}: ${cartItem.quantity}` }}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                    <div className="eleme-col col-right">
                                        {[Types.Product, Types.Bundle].includes(cartItem?.itemType) && (
                                            <div className="col-description">
                                                <div
                                                    className="title"
                                                    dangerouslySetInnerHTML={{ __html: cartItem.pricing?.finalPriceDisplay }}
                                                />
                                                {cartItem?.pricing?.finalPrice !== cartItem?.pricing?.marketingPrice && (
                                                    <div
                                                        className="subtitle"
                                                        dangerouslySetInnerHTML={{ __html: cartItem.pricing?.marketingPriceDisplay }}
                                                    />
                                                )}
                                            </div>
                                        )}
                                        {[Types.Voucher].includes(cartItem?.itemType) && (
                                            <div className="col-description">
                                                <div
                                                    className="title"
                                                    dangerouslySetInnerHTML={{ __html: cartItem.pricing?.finalPriceDisplay }}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="eleme-col col-control">
                                        <Button
                                            className="control"
                                            element={ButtonElements.Button}
                                            style={ButtonStyles.None}
                                            onClick={() => actions.removeOffer({ id: cartItem.id, cartItem })}
                                            icon={{
                                                value: '/images/cart/cart.svg',
                                                type: ButtonIconTypes.Image,
                                                position: ButtonIconPositions.After,
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
            <div className="summary">
                <div className="summary-header">
                    <div
                        className="title"
                        dangerouslySetInnerHTML={{ __html: translations?.title?.payment }}
                    />
                    <div className="price-compare">
                        <span className="price">{state?.cart?.pricing?.finalPriceDisplay}</span>
                        {state?.cart?.pricing?.finalPrice !== state?.cart?.pricing?.marketingPrice && (
                            <span className="marketing-price">{state?.cart?.pricing?.marketingPriceDisplay}</span>
                        )}
                    </div>
                </div>
                <div className="summary-controls">
                    <Button
                        className="control"
                        onClick={() => router.push(Routes.PublicCart)}
                        layout={ButtonLayouts.Block}
                    >
                        {translations?.buttonLabel}
                    </Button>
                </div>
            </div>
        </StyledComponent>
    );
};

export default PublicLayoutCartDropDownMenu;
