import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { ApiResponseBody, ReduxRequestPassOptions } from 'types/request';

import Cart from 'models/Cart';

import {
    removeOffer,
    RemoveOfferParams,
} from 'store/modules/carts/actions';
import { State } from 'store/state';

import Component from './component';

export interface Props extends ParentProps {
    actions?: {
        removeOffer: (params?: RemoveOfferParams, requestParams?: ReduxRequestPassOptions) => Promise<ApiResponseBody>;
    };
    state: {
        cart: Cart;
    }
}

export interface ParentProps {
    translations?: Translations;
}

export interface Translations {
    title: {
        cart: string;
        payment: string;
        quantity: string;
        noItems: string;
    };
    buttonLabel: string;
    voucherPlaceholder?: string;
}

const mapStateToProps = (state: State, ownProps?: Props): object => {
    return {
        state: {
            cart: state.cart.data,
        },
    };
};

const mapDispatchToProps = (dispatch: Dispatch): object => {
    return {
        actions: {
            removeOffer: bindActionCreators(removeOffer, dispatch),
        },
    };
};

Component.defaultProps = {
    translations: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(Component);
